// Core
import React, { useRef, useState, useEffect, useContext } from "react"
import { ContextDispatch, ContextState } from "../../context/Context"
import TransitionLink from "gatsby-plugin-transition-link"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"
import { maskOut } from "./../../utils/masks"
import { localiseString } from "./../../utils/localiseString"

// Components
import Menu from "./../menu"
import Support from "./../support"

// Imagery
import Logo from "./images/logo.inline.svg"

// Styles
import styles from "./header.module.scss"

const Header = ({ siteTitle, location = null }) => {
  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)
  const [loaded, setLoaded] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const donatePromptShown = useRef(store.donatePromptShown)

  const hideDonateModal = () => {
    storeDispatch({ type: "HIDE_DONATE_MODAL" })
  }

  const nonEpisode =
    location &&
    (location.pathname === "/" ||
      location.pathname.indexOf("about") !== -1 ||
      location.pathname.indexOf("episodes") !== -1)

  const toggleMenu = () => {
    if (store.showDonateModal && window.innerWidth < 1240) {
      hideDonateModal()
    } else {
      if (store.showDonateModal) {
        hideDonateModal()
      }
      setMenuOpen(!menuOpen)
    }
  }

  const checkScroll = (value, way, obj) => {
    if (value === "footer" && !donatePromptShown.current) {
      storeDispatch({ type: "SHOW_DONATE_MODAL" })
    }
  }

  useEffect(() => {
    donatePromptShown.current = store.donatePromptShown
  }, [store])

  useEffect(() => {
    setLoaded(true)
    setTimeout(() => {
      if (!isMobile) {
        window.scroll.on("call", checkScroll)
      }
    }, 200)
  }, [])

  useEffect(() => {
    if (menuOpen || store.showDonateModal) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }, [menuOpen])

  return (
    <React.Fragment>
      <header
        id="header"
        className={`${styles.header} ${
          nonEpisode ? "" : styles.episodeHeader
        } ${menuOpen ? styles.menuOpen : ""} ${loaded ? styles.loaded : ""}`}
      >
        <div
          id="header-inner"
          className={`${styles.inner} ${
            store.showDonateModal ? styles.supportOpen : ""
          } container flex flex--push flex--vert-align`}
        >
          <div className={styles.logo}>
            <TransitionLink
              title={siteTitle}
              to={`/`}
              exit={{
                delay: menuOpen ? 0 : 1,
                length: menuOpen ? 0 : 1,
                zIndex: 2,
              }}
              entry={{
                trigger: () => {
                  if (!isMobile) window.scroll.update()
                },
                delay: menuOpen ? 0 : 1,
                length: 0,
                zIndex: 1,
              }}
              onClick={menuOpen ? toggleMenu : () => maskOut()}
              aria-label={localiseString("Go to home page")}
            >
              <Logo />
            </TransitionLink>

            <Logo />
          </div>

          <div className={styles.mainMenuToggle}>
            <div className="flex flex--vert-align">
              <button
                className={`${styles.mainMenuBtn} ${styles.menuBtn}`}
                onClick={toggleMenu}
                aria-label={localiseString("Toggle Menu")}
              >
                <span />
                <span />
                <span />
                <span />
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className={styles.dummyMenuBtnWrap}>
        <button
          className={`${styles.dummyMenuBtn} ${styles.menuBtn}`}
          onClick={toggleMenu}
          aria-label={localiseString("Toggle Menu")}
        >
          <span />
          <span />
        </button>
      </div>

      <Menu
        loaded={loaded}
        open={menuOpen}
        closeEvent={toggleMenu}
        supportOpen={store.showDonateModal}
        currentPage={location && location.pathname}
      />
      <Support />
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
