// Core
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { isMobile } from "react-device-detect"
import Helmet from "react-helmet"

// Components
import Grid from "../_grid"
import Scroll from "./../locomotiveScroll"
import Header from "./../header"

// Styles
import "./base.scss"
import "./../locomotiveScroll/locomotive-scroll.css"

const Layout = ({ children, location, scroll = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let keyMode = false

  const setMouseMode = () => {
    if (!keyMode) return
    keyMode = false
    document.body.classList.remove("key-access")
  }

  const setKeyboardMode = () => {
    if (keyMode) return
    keyMode = true
    document.body.classList.add("key-access")
  }

  useEffect(() => {
    document.body.addEventListener("mousedown", setMouseMode)
    document.body.addEventListener("keydown", setKeyboardMode)

    return () => {
      document.body.removeEventListener("mousedown", setMouseMode)
      document.body.removeEventListener("keydown", setKeyboardMode)
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="google-site-verification"
          content="2WF8JWyg-7ZAC3Mh9-dXq2PaTVIFCMqtMBqPT_Ldw1E"
        />
      </Helmet>

      <Grid cols={12} />

      <Header siteTitle={data.site.siteMetadata.title} location={location} />

      {!isMobile && <Scroll callbacks={location} />}

      <div className="site-wrap">
        <div id="mask"></div>
        <div id="mask-textured"></div>
        <div id="mask-loader">
          <svg
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="30" cy="30" r="29" stroke="#E6E5E1" strokeWidth="2" />
            <circle cx="30" cy="30" r="29" stroke="#E6E5E1" strokeWidth="2" />
          </svg>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
