import gsap from 'gsap'

const maskOut = (params) => {
  let delay = (params && params.delay) ? params.delay : 0,
      selector = (params && params.selector) ? params.selector : "#mask",
      loader = document.querySelector('#mask-loader')

  document.querySelector(selector).classList.add('active')

  gsap.fromTo(selector, {
    y: "100%"
  }, {
    y: "0",
    duration: 1.25,
    ease: "power3.inOut",
    delay: delay,
    onStart: () => {
      loader.classList.add('active')
    }
  });
}

const maskIn = (params) => {
  let delay = (params && params.delay) ? params.delay : 0,
      force = (params && params.force) ? params.force : false

  const loader = document.querySelector('#mask-loader'),
        mask = document.querySelector('#mask'),
        maskTextured = document.querySelector('#mask-textured')

  if (mask.classList.contains('active') || force) {
    mask.classList.remove('active')
    gsap.fromTo(mask, {
      y: "0"
    }, {
      y: "-100%",
      duration: 1.75,
      ease: "power3.inOut",
      delay: delay,
      onStart: () => {
        loader.classList.remove('active')
      }
    });
  }

  if (maskTextured.classList.contains('active')) {
    maskTextured.classList.remove('active')
    gsap.fromTo(maskTextured, {
      y: "0"
    }, {
      y: "-100%",
      duration: 1.75,
      ease: "power3.inOut",
      delay: delay,
      onStart: () => {
        loader.classList.remove('active')
      }
    });
  }
}

export { maskOut, maskIn }