const stringRef = {
  "about": "sobre-nosotros",
  "About": "Sobre Nosotros",
  "About Sound/Stage": "Sobre Nosotros Sound/Stage",
  "About the episode": "Sobre el episodio",
  "Be the first to know when new episodes drop": "Sé el primero en enterarte del lanzamiento de nuevos episodios",
  "Copy link to clipboard": "Copiar enlace al portapapeles",
  "Credits": "Créditos",
  "Discover more": "Descubrir mas",
  "Discover released performances, explore upcoming episodes, and sign up for email notifications when new ones drop.": "Descubra los estrenos de las actuaciones, explore los próximos episodios y regístrese para recibir notificaciones por correo electrónico cuando aparezcan nuevos.",
  "Donate": "Donar",
  "Enter your Email": "Introduzca su email",
  "EP": "EP",
  "episodes": "episodios",
  "Episode": "Episodio",
  "Episodes": "Episodios",
  "Explore": "Explora",
  "Explore the series": "Explora las series",
  "Go to home page": "Ir a la página de inicio",
  "Home": "Inicio",
  "In conversation with": "Conversando con",
  "LA Phil": "LA Phil",
  "LA Phil Media Presents": "LA Phil Media presenta",
  "Latest episode": "Último Episodio",
  "Launches": "Lanzamientos",
  "Listen on Spotify": "Esucha en Spotify",
  "Media Presents": "Media presenta",
  "mins": "mins",
  "Program": "Programa",
  "Runtime": "Duración",
  "Run time": "Duración",
  "Share": "Compartir",
  "Share this page": "Compartir esta página",
  "Share this page by email": "Compartir esta página por correo electronico",
  "Sign me up": "Registrarme",
  "Season": "Temporada",
  "Support the LA Phil": "Apoya a la LA Phil",
  "Stay tuned": "Mantente al tanto",
  "Thanks for Subscribing!": "Gracias por subscribirse!",
  "There has been an error submitting your email address.": "Ha habido un error al usar su correo electrónico.",
  "This concert’s streaming date has passed. Enjoy its accompanying essay, interview, special performances and more below.": "La fecha de transmisión de este concierto ha expirado. Disfrute de su ensayo, entrevista, presentaciones especiales y más a continuación.",
  "This episode was released on": "Este episidio fue lanzado el",
  "Toggle Menu": "Menú desplegale",
  "Watch Next": "Ver el siguiente",
  "Watch Now": "Ver ahora",
  "Essay": "Ensayo",
  "Gallery": "Galería",
  "Interview": "Entrevista",
  "Performance": "Actuación",
  "Playlist": "Lista de Reproducción",
  "Extras": "Extras",
}

function localiseString(string) {
  let esUS = stringRef[string]

  // if (esUS === undefined) {
  //   console.log("--------------------------------------------------")
  //   console.log(string + " not yet added in localisation object.")
  //   console.log("--------------------------------------------------")
  // }

  if (process.env.GATSBY_CONTENTFUL_LOCALE === "en-US")
    return string
  else if (process.env.GATSBY_CONTENTFUL_LOCALE === "es-US")
    return (esUS ? esUS : string)
  else
    return string
}

export { localiseString }