// Core
import React, { useContext, useState, useEffect } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { isMobile } from "react-device-detect"
import { maskOut } from "../../utils/masks"
import { localiseString } from "../../utils/localiseString"

// Styles
import styles from "./menu.module.scss"
import { SlideMaskText } from "../slideMaskText"
import { ContextDispatch } from "../../context/Context"

import SelectArrow from "./select.inline.svg"

const Menu = ({ loaded, open, closeEvent, currentPage, supportOpen }) => {
  const storeDispatch = useContext(ContextDispatch)
  const [locale, setLocale] = useState("en")
  const [langIsOpen, setLangIsOpen] = useState(false)

  const toggleModal = e => {
    e.preventDefault()
    storeDispatch({ type: "TOGGLE_DONATE_MODAL" })
  }

  const checkLocale = () => {
    if (process.env.GATSBY_CONTENTFUL_LOCALE === "en-US") {
      setLocale("en")
    } else if (process.env.GATSBY_CONTENTFUL_LOCALE === "es-US") {
      setLocale("es")
    }
  }

  useEffect(() => {
    checkLocale()
  }, [])

  const menuItem = (slug, title) => {
    let isCurrentPage = false

    if (!slug || slug.length === 0) {
      if (currentPage && currentPage === "/") {
        isCurrentPage = true
      }
    } else if (currentPage && currentPage.indexOf(slug) > -1) {
      isCurrentPage = true
    }

    return (
      <li
        className={`${styles.item} ${isCurrentPage ? styles.current : ""}`}
        key={`menu-item-${title}`}
      >
        <TransitionLink
          to={"/" + slug}
          exit={{
            delay: open ? 0 : 1,
            length: open ? 0 : 1,
            zIndex: 2,
          }}
          entry={{
            trigger: () => {
              if (!isMobile) window.scroll.update()
            },
            delay: open ? 0 : 1,
            length: 0,
            zIndex: 1,
          }}
          onClick={open ? closeEvent : () => maskOut()}
        >
          <SlideMaskText text={title} />
        </TransitionLink>
      </li>
    )
  }

  return (
    <React.Fragment>
      <div
        id="header-menu"
        className={`${styles.wrap} ${open ? styles.open : ""} ${
          supportOpen ? styles.supportOpen : ""
        }`}
      >
        <div
          className={`${styles.inner} ${loaded ? styles.loaded : ""} container`}
        >
          <div className={styles.list}>
            <ul className={styles.listMain}>
              {menuItem("", localiseString("Home"))}
              {menuItem("episodes", localiseString("Episodes"))}
              {menuItem("about", localiseString("About"))}

              <li className={styles.item}>
                <a
                  href="https://www.laphil.com/support/give-now"
                  target="_blank"
                  onClick={toggleModal}
                >
                  <SlideMaskText text={localiseString("Donate")} />
                </a>
              </li>

              <li className={`${styles.item} ${styles.itemLang}`}>
                {locale === "en" ? (
                  <span className={styles.mobileLang}>
                    <a
                      href="https://soundstage.laphil.com"
                      className={styles.langDisabled}
                    >
                      EN
                    </a>{" "}
                    <span className={styles.langDivide}>|</span>{" "}
                    <a href="https://es.soundstage.laphil.com">ES</a>
                  </span>
                ) : (
                  <span className={styles.mobileLang}>
                    <a
                      href="https://es.soundstage.laphil.com"
                      className={styles.langDisabled}
                    >
                      ES
                    </a>{" "}
                    <span className={styles.langDivide}>|</span>{" "}
                    <a href="https://soundstage.laphil.com">EN</a>
                  </span>
                )}
                {locale === "en" ? (
                  <span
                    name=""
                    id=""
                    className={styles.deskLang}
                    data-lang-open={langIsOpen}
                    onClick={() => {
                      setLangIsOpen(!langIsOpen)
                    }}
                  >
                    EN <SelectArrow />
                    <span className={styles.secondLang}>
                      {" "}
                      <a href="https://es.soundstage.laphil.com">ES</a>
                    </span>
                  </span>
                ) : (
                  <span
                    name=""
                    id=""
                    className={styles.deskLang}
                    data-lang-open={langIsOpen}
                    onClick={() => {
                      setLangIsOpen(!langIsOpen)
                    }}
                  >
                    ES <SelectArrow />
                    <span className={styles.secondLang}>
                      <a href="https://soundstage.laphil.com">EN</a>
                    </span>
                  </span>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Menu
