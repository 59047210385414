// Core
import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ContextDispatch, ContextState } from "../../context/Context"
import { localiseString } from "../../utils/localiseString"

// Styles
import styles from "./support.module.scss"

// Components
import { SlideMaskText } from "../slideMaskText"

const Support = () => {
  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)
  const data = useStaticQuery(graphql`
    query DonateModalContent {
      allContentfulDonateModal {
        nodes {
          title
          text {
            text
          }
          cta
          supportLink
          supportLinkText
          donateOptions {
            text
            url
          }
        }
      }
    }
  `)

  const {
    text,
    cta,
    supportLink,
    supportLinkText,
    donateOptions,
  } = data.allContentfulDonateModal.nodes[0]

  const close = e => {
    if (e) e.preventDefault()
    storeDispatch({ type: "TOGGLE_DONATE_MODAL" })
  }

  return (
    <div
      className={`${styles.wrap} ${store.showDonateModal ? styles.open : ""}`}
      onClick={close}
    >
      <div
        className={styles.background}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <button className={styles.closeBtn} onClick={close}>
          <span />
          <span />
        </button>

        <div className={`${styles.inner} container`}>
          <div className={styles.content}>
            <h6 className={styles.title}>
              {localiseString("Support the LA Phil")}
            </h6>

            <p className={styles.text}>{text.text}</p>

            <div className={styles.donateContent}>
              <p className={styles.subtitle}>{cta}</p>

              <div className={styles.buttons}>
                {donateOptions.map(btn => (
                  <a
                    key={btn.text}
                    className="button button--filled-dark"
                    href={btn.url}
                    target="_blank"
                  >
                    <SlideMaskText text={btn.text} />
                  </a>
                ))}
              </div>

              <div className={styles.button}>
                <a
                  href={supportLink}
                  className="button button--dark"
                  target="_blank"
                >
                  <SlideMaskText text={supportLinkText} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support
