import React from "react"

// Style
import styles from "./grid.module.scss"

function Grid({ cols }) {
  const gridLines = []

  for (let x = 0; x < (cols * 2 - 2); x++) {
    gridLines.push(<span key={x}></span>)
  }

  return (
    <div className={styles.gridlines}>
      {gridLines}
    </div>
  )
}

export default Grid
