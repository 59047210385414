import React from "react"
import PropTypes from "prop-types"
import styles from "./slideMaskText.module.scss"

const SlideMaskText = ({ text }) => (
  <span className={styles.slideMask}>
    <span className={styles.slideMaskChild}>{text}</span>
    <span className={styles.slideMaskChild} aria-hidden>
      {text}
    </span>
  </span>
)

SlideMaskText.propTypes = {
  text: PropTypes.string,
}

export { SlideMaskText }
