import React, { useReducer, createContext } from "react"

export const ContextState = createContext()
export const ContextDispatch = createContext()

const initialState = {
  showDonateModal: false,
  donatePromptShown: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DONATE_MODAL": {
      return {
        ...state,
        donatePromptShown: true,
        showDonateModal: !state.showDonateModal,
      }
    }
    case "SHOW_DONATE_MODAL": {
      return {
        ...state,
        donatePromptShown: true,
        showDonateModal: true,
      }
    }
    case "HIDE_DONATE_MODAL": {
      return {
        ...state,
        showDonateModal: false,
      }
    }

    default:
      throw new Error("Bad action type")
  }
}

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>
        {children}
      </ContextDispatch.Provider>
    </ContextState.Provider>
  )
}

export default Context
